import { usePageData } from "~core/hooks/use-page";
import styles from "./IssueModule.module.css";

import { IssueCoverImage, IssueDate } from "~/features/issue/IssuePage";
import ArticleRow from "./ArticleRow";
import { IMAGE_LAYOUT_OPTIONS } from "./ResponsiveImage";
import { getLinkForIssue } from "~lib/utils";

export default function IssueModule({ issue, title }) {
  const issueArticles = issue.article_previews.features.slice(0, 6);
  const issueURL = getLinkForIssue(issue);
  return (
    <section className={styles.latest_issue}>
      <h2 className={styles.latest_issue_main_text}>
        {!title && (
          <>
            <IssueDate date={issue.issue_date} />{" "}
            <span className={styles.latest_issue_main_text__extended}>Issue</span>
          </>
        )}
        {title && <span className={styles.latest_issue_main_text__extended}>{title}</span>}
      </h2>
      <div className={styles.latest_issue_layout}>
        {issue.image_url && (
          <a
            href={issueURL}
            className={styles.latest_issue_column__issue_cover}
            aria-label={`Open table of contents for issue ${issue.issue_date}`}
          >
            <IssueCoverImage url={issue.image_url} />
          </a>
        )}

        <div className={styles.latest_issue_column_secondary}>
          {issueArticles.map((article, index) => {
            return (
              <ArticleRow
                articleData={article}
                clickTrackKey={"homepage-issuemodule-" + index + "-"}
                key={article.contentful_id}
                lastElement={index === issueArticles.length - 1}
                isDarkMode
                showCategory
                showPublishedDate={false}
              />
            );
          })}
        </div>
        <div className={styles.latest_issue_links}>
          <a href={issueURL} className={styles.view_issue_btn}>
            View Full Issue
          </a>
          <a href={"/issues/"} className={styles.explore_archive_btn}>
            Explore Archive
          </a>
        </div>
      </div>
    </section>
  );
}

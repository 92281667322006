// Similar to display article but with special Homepage behavior variations.
import { handleTrackClick } from "~lib/analytics/handleTrackClick";
import { getBestArticleId } from "~/lib/getBestArticleId";
import { cx, formatAuthors } from "~utils";
import { Kicker } from "~ui/Kicker";
import { IMAGE_LAYOUT_OPTIONS, ResponsiveImage } from "./ResponsiveImage";

import styles from "./article-row.module.css";

/**
 *
 * @param {{
 *  articleData: object,
 *  clickTrackKey: string,
 *  imageLayout: number - use the enums from IMAGE_LAYOUT_OPTIONS
 * }} param0
 * @returns
 */
export default function ArticleRow({
  articleData,
  clickTrackKey = "unspecified-article-row-",
  imageLayout = IMAGE_LAYOUT_OPTIONS.alwaysSmall,
  lastElement,
  isDarkMode = false,
  showCategory = false,
  showPublishedDate = true,
}) {
  let { image_url } = articleData;
  const {
    authors,
    release_date,
    date_published,
    display_date,
    url,
    display_title,
    image_alt_text,
    category,
    custom_category,
    subtype,
    digital_column,
    contentful_id,
  } = articleData;
  const storyAuthors = formatAuthors(authors);

  // fallback img for podcasts w/o one
  if (!image_url && subtype === "Podcast") {
    // @TODO: use an asset import for this
    image_url = "https://www.scientificamerican.com/static/science-quickly-logo.jpg";
  }

  // Create classes for image layout controls
  // The row will be styled differently if we need a large image,
  // changing the flex direction at whatever breakpoint
  let rowClass = null;
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.alwaysLarge) {
    rowClass = styles.rowImageLarge;
  }
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.desktopLarge) {
    rowClass = styles.rowDesktopLarge;
  }

  // These figures/img will be styled different based on layout controls
  let imageWrapperLayoutClass = null;
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.desktopHide) {
    imageWrapperLayoutClass = styles.imageLayoutDesktopHide;
  }

  const TITLE_ARIA_LABEL = `displayTitle-${contentful_id}`;

  return (
    <article
      className={cx(
        styles.rowWrapper,
        lastElement ? styles.borderBottomMobile : "",
        isDarkMode ? styles.isDarkMode : "",
      )}
    >
      <div className={cx(styles.row, rowClass)}>
        {imageLayout !== IMAGE_LAYOUT_OPTIONS.none && image_url && (
          <div className={cx(styles.imageWrapper, imageWrapperLayoutClass)}>
            <a
              className={styles.link}
              href={url}
              onClick={(e) => {
                handleTrackClick(clickTrackKey + getBestArticleId(articleData), e);
              }}
              aria-labelledby={TITLE_ARIA_LABEL}
            >
              <figure className={styles.figure}>
                {/* @TODO Size logic under many different circumstances */}
                <ResponsiveImage
                  className={styles.image}
                  image_url={image_url}
                  imageLayout={imageLayout}
                  alt={image_alt_text || ""}
                  loading="lazy"
                />
              </figure>
            </a>
          </div>
        )}

        <div className={styles.contentWrapper}>
          <Kicker
            category={category}
            custom_category={custom_category}
            display_date={display_date}
            release_date={date_published || release_date}
            showCategory={showCategory}
            showPublishedDate={showPublishedDate}
            subtype={subtype}
            digital_column={digital_column}
            isDarkMode={isDarkMode}
          />
          <a
            className={styles.link}
            href={url}
            onClick={(e) => {
              handleTrackClick(clickTrackKey + getBestArticleId(articleData), e);
            }}
            aria-labelledby={TITLE_ARIA_LABEL}
          >
            <h2
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: display_title }}
              id={TITLE_ARIA_LABEL}
            ></h2>
          </a>
          <p className={styles.authors}>{storyAuthors}</p>
        </div>
      </div>
    </article>
  );
}

import { handleTrackClick } from "~lib/analytics/handleTrackClick";
import { usePageData } from "~core/hooks/use-page";
import { cx } from "~utils";
import styles from "./GamesModule.module.css";

import { IMAGE_LAYOUT_OPTIONS, ResponsiveImage } from "./ResponsiveImage";

/**
 * @param {{
 *  seriesData: object,
 *  clickTrackKey: string,
 *  imageLayout: number - use the enums from IMAGE_LAYOUT_OPTIONS
 * }} param0
 * @returns
 */
export function GameCard({
  seriesData,
  index,
  imageLayout = IMAGE_LAYOUT_OPTIONS.alwaysSmall,
  firstElement,
}) {
  const { description, display_title, slug, url, contentful_id } = seriesData;

  let { image_url, image_alt_text } = seriesData;

  if (image_url === null) {
    image_url =
      "https://static.scientificamerican.com/dam/m/170d5c1b1809219e/original/GAMES-ICONS-all.jpg";
    image_alt_text =
      "A robot hand unleashes a swirl of puzzle pieces, crosswords, and circles with numbers and letters";
  }

  // TODO: udpate this if we use these outside the homepage?
  const clickTrackKey = `homepage-gamesmodule-${index}-${contentful_id}`;

  // Create classes for image layout controls
  // The row will be styled differently if we need a large image,
  // changing the flex direction at whatever breakpoint
  let rowClass = null;
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.alwaysLarge) {
    rowClass = styles.rowImageLarge;
  }
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.desktopLarge) {
    rowClass = styles.rowDesktopLarge;
  }

  // These figures/img will be styled different based on layout controls
  let imageWrapperLayoutClass = null;
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.desktopHide) {
    imageWrapperLayoutClass = styles.imageLayoutDesktopHide;
  }

  return (
    <article className={cx(styles.cardContent, firstElement ? "first-child" : null)}>
      <div className={cx(styles.imageWrapper, imageWrapperLayoutClass)}>
        <a
          className={styles.link}
          href={url}
          onClick={(e) => {
            handleTrackClick(clickTrackKey, e);
          }}
        >
          <figure className={styles.figure}>
            <ResponsiveImage
              className={styles.image}
              image_url={image_url}
              imageLayout={imageLayout}
              alt={image_alt_text || ""}
              loading="lazy"
            />
          </figure>
        </a>
      </div>
      <div className={styles.seriesText}>
        <a
          className={styles.link}
          href={url}
          onClick={(e) => {
            handleTrackClick(clickTrackKey, e);
          }}
        >
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: display_title }}></h2>
        </a>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
    </article>
  );
}

export default function GamesModule() {
  const { gameSeries } = usePageData();

  return (
    <section className={styles.gamesModule}>
      <div className={styles.headerWrapper}>
        <h2 className={styles.header}>Games</h2>
      </div>

      <div className={styles.games_module_layout}>
        {gameSeries.map((series, index) => {
          return (
            <GameCard
              seriesData={series}
              key={series.contentful_id}
              index={index}
              firstElement={index === 0}
              imageLayout={IMAGE_LAYOUT_OPTIONS.alwaysSmall}
            />
          );
        })}
      </div>
    </section>
  );
}

import { usePageData } from "~core/hooks/use-page";
import styles from "./MostPopular.module.css";
import ArticleList from "~features/article-list/ArticleList";

export default function MostPopular() {
  const { recommendedStories: mostPopular } = usePageData();

  return (
    <section className={styles.mostPopularWrapper}>
      <h2 className={styles.mostPopularMainText}>Popular Stories</h2>
      <ArticleList
        hasBorderBottom
        results={mostPopular}
        clickTrackKey={"popularmodule-"}
        maxExpectedItems={6}
      />
      <a className={styles.mostPopularBtn} href="/latest/">
        View All Stories
      </a>
    </section>
  );
}
